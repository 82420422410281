<template>
    <div>
        <!-- <div class="user-avatar">
            <el-avatar :size="46">
                <img :src="user.photo_url" alt/>
            </el-avatar>
            <span class="m-left-3 user-name">{{ user.name }}</span>
        </div> -->
        <div class="m-top-4"> 
            <textarea
                class="form-control"
                placeholder="Enter your comment"
                rows="3"
                v-model="comment_value"
            ></textarea>
        </div>
        <div class="m-top-3 text-right">
            <el-button @click="clearComment" round type="info" v-if="!user.hasOwnProperty('name')"  style="opacity:0.5;cursor: not-allowed;">Cancel</el-button>
            <el-button v-if="!user.hasOwnProperty('name')" round type="primary" style="opacity:0.5;cursor: not-allowed;">Comment</el-button>
            <el-button @click="clearComment" v-if="user.hasOwnProperty('name')" round type="info">Cancel</el-button>
            <el-button @click="createComment" v-if="user.hasOwnProperty('name')" round type="primary">Comment</el-button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CommentEditor",
        props: {
            user: Object
        },
        data: () => ({
            comment_value: ""
        }),
        beforeDestroy() {
            window.vEvent.stop("clear-stage-video-comment", this.clearComment);
        },
        mounted() {
            window.vEvent.listen("clear-stage-video-comment", this.clearComment);
        },
        methods: {
            clearComment() {
                this.comment_value = "";
            },

            createComment() {
                this.$emit("create-stage-video-comment", {
                    comment: null,
                    comment_str: this.comment_value,
                    children: false
                });
            }
        }
    };
</script>
