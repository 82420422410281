<template>
  <div class="main-embed-container">
    <div class="header-embed">
      <div class="image">
        <a :href="'https://adilo.com/'" target="_blank">
          <img alt="Adilo" class="logo" src="../../assets/4kplayerlogo.svg">
        </a>
      </div>
      <div class="header-links">
        <a
            class=""
            href="https://adilo.com/product/hosting"
            target="_blank"
        >Business Video Hosting</a>
        <a
            class=""
            href="https://adilo.com/product/player"
            target="_blank"
        >4K Player</a>
        <a href="https://adilo.com/signup" target="_blank" class="btn btn-primary">Join Adilo</a>
      </div>

    </div>
    <div class="child-embed-container">
      <div class="inner-child-container">
        <div id="embedVideo">
          <div style="width: 100%; height: 100%; position: relative; padding-top: 56.25%;"><iframe style="position: absolute; top: 0; left: 0; width: 100%; height: 100%" allowtransparency="true" :src="this.embedUrl" frameborder="0" allowfullscreen mozallowfullscreen webkitallowfullscreen oallowfullscreen msallowfullscreen scrolling="no"></iframe></div><!--          <Video-->
<!--              :bandwidthData='bandwidthData'-->
<!--              :ownerStatus='ownerStatus'-->
<!--              :video="video.full"-->
<!--              @changevideo="video = $event"-->
<!--              v-if="video" ref="video"-->
<!--          ></Video>-->
{{this.embedUrlLink}}
        </div>
      </div>
      <EmbedVideoDetail
          :video="video"
          ref="video"
      ></EmbedVideoDetail>
      <VideoComments
          :current-time="currentTime"
          :user="user"
          :video-full="full_video"
          :stageId="videoId"
      ></VideoComments>
    </div>

  </div>

</template>

<script>
// var Video = require('../../components/Video.vue');
var EmbedVideoDetail = require('../../components/EmbedVideoDetail.vue');
var VideoComments = require('../../components/Stage/Public/VideoComments.vue');

export default {
  name: 'PublicVideo',
  components: {
    // Video: Video,
    EmbedVideoDetail: EmbedVideoDetail,
    VideoComments: VideoComments,
  },
  props: {
    user: Object,
  },
  data() {
    return {
      video: false,
      videoId: null,
      videoLoaded: false,
      playlistId: false,
      lockedVideo: false,
      activeOwner: true,
      ownerStatus: null,
      bandwidthData: null,
      currentTime: 0,
      windowWidth: null,
      full_video: {
        comments: [],
        full: {
          comments: [],
          player_options: ''
        }
      },
      embedUrlLink: '',
      embedUrl: '',
    }
  },
  mounted() {


    this.getVideoData();
    this.videoId = this.getVideoId();
// console.log(this.videoId, 'this.videoId');

  },
  created() {

    console.log('created');

  },
  methods: {
    appendData() {
      var json = {
        "@context": "http://schema.org/",
        "@type": "VideoObject",
        "name": this.video.full.title,
        "contentUrl": this.video.full.embed_url,
        "duration": this.video.full.duration_formatted,
        "thumbnailUrl": this.video.full.thumbnail,
        "uploadDate": this.video.full.created_at,
        "description": this.video.full.description,
        "author": {
          "@type": "Person",
          "name": this.video.owner_name,
          "image": this.video.owner_logo
        }
      }
      // console.log(this.video, json, 'json');
      var html = "<script type='application/ld+json'>" +
          JSON.stringify(json) + "\<\/script>";

      html += '<meta name="title" content="' + this.video.full.title + '">';
      html += '<meta name="description" content="' + this.video.full.description + '">';
      html += '<meta property="og:type" content="video">';
      html += '<meta property="og:url" content="' + this.video.full.embed_url + '">';
      html += '<meta property="og:title" content="' + this.video.full.title + '">';
      html += '<meta property="og:description" content="' + this.video.full.description + '">';
      html += '<meta property="og:image" content="' + this.video.full.thumbnail + '">';
      html += '<meta property="twitter:card" content="summary_large_image">';
      html += '<meta property="twitter:url" content="' + this.video.full.embed_url + '">';
      html += '<meta property="twitter:title" content="' + this.video.full.title + '">';
      html += '<meta property="twitter:description" content="' + this.video.full.description + '">';
      html += '<meta property="twitter:image" content="' + this.video.full.thumbnail + '">';
        // console.log(document.head,  html, json, 'json');
      document.head.innerHTML = document.head.innerHTML + html
      // document.getElementsByTagName('head')[0].appendChild(html);
    },

    getVideoId() {
      var hashValue = window.location.hash.substr(1);
      if (hashValue.indexOf('/video/') == 0)
        return hashValue.replace('/video/', '').split("?")[0].split("/")[0];
      var path = window.location.pathname;
      if (path.indexOf('/video/') == 0)
        return path.replace('/video/', '').split('?')[0].split('/')[0];
    },

    getVideoData() {
      let params = {
        url: 'video-watch/public/get-stage-video-by-id?video_id=' + this.getVideoId(),
        config: null,
      };

      this.$store
          .dispatch("getData", {...params})
          .then((r) => {
            if (r.data.result === "success") {
              this.video = r.data.video;
              this.full_video = r.data.video;
                this.embedUrl = `${process.env.VUE_APP_BACKEND_URL}watch/${this.videoId}`;
                document.title = `${this.video.full.title} | Adilo`;
                // console.log(document.title, 'document.title');
              this.appendData()
            } else {
              window.vEvent.fire("stage-common-error", "Video not found.");
            }
          })
          .catch((e) => {
            window.vEvent.fire("stage-common-error", "Video not found.");
          });
    },
  },
}
</script>

<style lang="less">
@import '~element-ui/lib/theme-chalk/index.css';
@import "../../assets/less/bootstrap/theme";
@import "../../assets/less/variables";
@import "../../assets/less/general-hacks";
@import "../../assets/less/custom/pages";

#app {
  padding-top: 10px;
}

body, html {
  font-family: "Helvetic Neue", Helvetica, Arial;
  padding: 0;
  margin: 0;
  background-color: #f9fbfb !important;
  height: auto !important;
}

img {
  vertical-align: middle;
}

.vjs_video_3-dimensions.vjs-fluid {
  padding-top: 0;
}

.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-circle {
  border-radius: 50%;
}

.lockedVideo {
  height: 100vw !important;
  width: 100vw;
  position: fixed;
  background: black;
  top: 0;
  left: 0;
  z-index: 400;
  color: rgb(193, 198, 206);
  text-align: center;
  font-size: 24px;
  line-height: 15;
}

.restrictedVideo {
  height: 100% !important;
  width: 100% !important;
  background: #101010;
  color: #fff;
  font-size: 40px;
  text-align: center;
}

.restrictedVideo .warningText {
  position: absolute;
  right: 0;
  left: 0;
  top: 50%;
}

.restrictedVideo .warningText img {
  margin-bottom: 25px;
}

//new css
.main-embed-container {
  max-width: 960px;
  margin: 0 auto;

  .btn {
    display: inline-block;
    margin-bottom: 0;
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    cursor: pointer;
    background-image: none;
    border: none !important;
    white-space: nowrap;
    padding: 8px 25px !important;
    font-size: 14px;
    line-height: 1.42857143;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .btn-primary {
    color: #fff;
    background-color: #0dabd8;
    border-color: #0dabd8;
    border-radius: 30px;
    padding: 8px 25px;
    font-weight: 600;
    border: none !important;
  }

  .header-embed {
    .header-links {
      display: inline-block;
      float: right;
      line-height: 80px;

      a {
        text-decoration: none;
        color: #21455e;
        font-size: 18px;
        font-weight: 600;
        margin-right: 20px;
      }
    }

    .image {
      display: inline-block;

      img {
        width: 225px;
      }
    }
  }

  .child-embed-container {
    background-color: #fff;
    //overflow: hidden;
    padding: 2.5rem 5rem;

    .inner-child-container {
      .video-js .vjs-tech {
        position: relative !important;
      }
    }
  }
}

#kayako-messenger{
    display:none;
}

@media (max-width: 1050px) {
  .main-embed-container {
    max-width: 800px;
  }

  .main-embed-container {
    .child-embed-container {
      background-color: #fff;
      padding: 1.5rem 2rem;
    }
  }
}

@media (max-width: 800px) {
  .header-embed {
    margin: 0 2rem;
    padding: 15px 0;
  }

  .main-embed-container .header-embed .image img {
    width: 160px;
  }

  .main-embed-container .header-embed .header-links {
    line-height: 55px;
  }

  .main-embed-container .header-embed .header-links a {
    font-size: 15px;
    margin-right: 10px;
  }

  .main-embed-container .header-embed btn {
    padding: 4px 15px !important;
  }

  .embed-video-detail .video-title {
    font-size: 17px !important;
    line-height: 23px !important;
  }
}

@media (max-width: 620px) {
  .main-embed-container .header-embed .image img {
    width: 110px;
  }

  .main-embed-container .header-embed .header-links {
    line-height: 40px;
  }

  .main-embed-container .header-embed .header-links a {
    font-size: 13px;
    font-weight: 500;
  }

  .main-embed-container .header-embed btn {
    padding: 4px 8px !important;
    font-size: 13px;
  }
}

@media (max-width: 520px) {
  .main-embed-container .header-embed .header-links {
    display: none;
  }

  .main-embed-container .child-embed-container {
    padding: 1.5rem 1rem !important;
  }
}
</style>