<template>
  <div class="comment-shows">
    <div :class="{ 'sub-user-avatar': childComment }" class="user-avatar">
      <el-avatar :size="avatarSize">
        <img :src="comment.user.photo_url" alt />
      </el-avatar>
    </div>
    <div class="comment-inner">
      <el-row :gutter="30">
        <el-col :span="8" :xs="30">{{ comment.user.name }}</el-col>
        <el-col :span="8" :xs="30">{{ comment.commented_at }}</el-col>
        <el-col :span="8" :xs="30" class="comment-reply-action" v-if="user">
          <span
            @click="deleteComment(comment)"
            class="reply-item"
            v-if="
             user && ( user.id === comment.user.id || user.id === videoFull.full.owner)
            "
          >Delete</span>
          <img alt src="../../../assets/img/stage/middle-dot.svg" />
          <span @click="showReply" class="reply-item">Reply</span>
        </el-col>
      </el-row>
      <div class="comment-content">{{ comment.body }}</div>

      <div
        @click="comment.showReply = !comment.showReply"
        class="view-reply-btn cursor-pointer"
        v-if="!childComment && comment.children.length"
      >
        <span v-if="!comment.showReply">View {{ comment.children.length }} Replies</span>
        <span v-if="comment.showReply">Hide {{ comment.children.length }} Replies</span>
        <img alt src="../../../assets/img/stage/arrow-down.svg" v-if="!comment.showReply" />
        <img alt src="../../../assets/img/stage/arrow-up.svg" v-if="comment.showReply" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CommentShow",
  props: {
    comment: Object,
    avatarSize: Number,
    childComment: Boolean,
    user: Object,
    videoFull: Object,
    parentComment: Object
  },
  methods: {
    deleteComment(comment) {
      this.$emit("delete-stage-video-comment", comment);
    },

    showReply() {
      this.parentComment.showReply = true;
      this.parentComment.showReplyInput = true;
    }
  }
};
</script>

<style lang="less" scoped>
.comments {
  list-style-type: none;
}
.comment-shows {
  display: flex;
  width: 100%;

  .user-avatar {
    width: 50px;

    &.sub-user-avatar {
      width: 30px;
    }
  }

  .comment-inner {
    margin-left: 20px;
    margin-top: 3px;
    //NEMESIS REMOVE width: ~"calc(100% - 120px)";
    font-size: 14px;
    font-weight: 400;
    min-width: 390px;
    .comment-reply-action {
      color: #61798b;

      .reply-item {
        cursor: pointer;

        &:first-child {
          margin-right: 1rem;
        }

        &:hover {
          color: #21455e;
        }

        &:last-child {
          margin-left: 1rem;
        }
      }
    }

    .comment-content {
      margin-top: 1rem;
      line-height: 1.6;
    }

    .view-reply-btn {
      margin-top: 1rem;
      font-size: 13px;
      font-weight: 500;
      color: #6a8498;
      width: 150px;
    }
  }
}
</style>
