<template>
  <div class="stage-video-comments-wrap">
    <div class="alam form-group">
      <span
        class="commets"
        style="margin-right:15px;"
        v-if="loginUser.hasOwnProperty('name')"
      >Comment as {{loginUser.name}}</span>
      <span
        class="commets"
        style="margin-right:15px;"
        v-if="!loginUser.hasOwnProperty('name')"
      >Sign In To Comment</span>
      <a
        href="javascript:void(0)"
        style="margin-right:30px;"
        v-if="!loginUser.hasOwnProperty('name')"
      >
        <GoogleLogin :params="params" :onSuccess="onSuccess" :onFailure="onFailure">
          <img src="../../../assets/img/social/google.jpg" alt style="width:47px" />
        </GoogleLogin>
      </a>
      <a
        href="javascript:void(0)"
        style="margin-right:30px;position:relative"
        v-if="loginUser.hasOwnProperty('name')"
        class="social_login"
      >
        <i class="el-icon-close" @click="godashboard()"></i>
        <img :src="loginUser.photo_url" alt style="width:47px;border-radius: 28px;" />
      </a>
      <a href="javascript:void(0)" style="margin-right:30px;" v-if="!loginUser.hasOwnProperty('name')">
        <img
          src="../../../assets/img/social/fb.png"
          @click="logInWithFacebook"
          alt
          style="width:50px"
        />
      </a>
      <!-- <a href="javascript:void(0)" style="margin-right:30px;">
        <img src="../../../assets/img/social/twitter.png" alt style="width:47px" />
      </a>-->
    </div>
    <div class="comments-title">
      Comments
      <span class="font-weight-normal">{{ formatNumber(videoFull.comments.length) }}</span>
    </div>

    <div
      v-if="(videoFull.full.player_options.commenting_permissions === 'everyone') || (videoFull.full.player_options.commenting_permissions === 'collaborators' && videoFull.full.team === user.currentTeam.id) || (videoFull.full.player_options.commenting_permissions === 'inherit' && videoFull.owner_id === user.id)"
    >
      <comment-editor :user="user || loginUser" v-on:create-stage-video-comment="createComment"></comment-editor>
    </div>
    <div v-if="videoFull.comments.length">
      <div class="hr"></div>

      <ul class="comments no-styling" v-if="videoFull.comments.length">
        <li
          :class="'comment-' + comment.id"
          :data-id="comment.id"
          :key="`comment-${index}`"
          class="comment clearfix"
          v-for="(comment, index) in videoFull.comments"
        >
          <comment-show
            :avatar-size="46"
            :child-comment="false"
            :comment="comment"
            :parent-comment="comment"
            :user="user || loginUser"
            :video-full="videoFull"
            v-on:delete-stage-video-comment="deleteComment"
          ></comment-show>

          <ul class="sub-comments" v-if="comment.children.length" v-show="comment.showReply">
            <li
              :class="'comment-' + comment.id"
              :data-id="comment.id"
              :key="`child-comment-${childIndex}`"
              class="comment clearfix"
              v-for="(child, childIndex) in comment.children"
            >
              <comment-show
                :avatar-size="30"
                :child-comment="true"
                :comment="child"
                :parent-comment="comment"
                :user="user"
                :video-full="videoFull"
                v-on:delete-stage-video-comment="deleteComment"
              ></comment-show>
            </li>
          </ul>

          <div class="comments-reply-line" v-if="comment.showReplyInput">
            <el-avatar :size="30" class="reply-avatar">
              <img :src="user.photo_url" alt />
            </el-avatar>

            <el-input
              class="comment-reply-input custom-el-input"
              placeholder="Enter your reply"
              v-model="comment_reply"
            ></el-input>

            <el-button
              @click="createComment({comment: comment, comment_str: comment_reply, children: true})"
              class="comment-reply-action"
              round
              type="primary"
            >Reply</el-button>

            <el-button
              @click="cancelReply(comment)"
              class="comment-reply-action"
              round
              type="info"
            >Cancel</el-button>
          </div>
        </li>
      </ul>

      <div
        class="p-top-2 m-bottom-2 text-light-color"
        v-else
      >There are no comments on this video yet.</div>
    </div>
    <div class="p-top-2 m-bottom-2 text-light-color" v-else>Comment is unavailable.</div>
  </div>
</template>

<script>
import CommentEditor from "./CommentEditor";
import CommentShow from "./CommentShow";
import GoogleLogin from "vue-google-login";

export default {
  name: "VideoComments",
  components: { CommentShow, CommentEditor, GoogleLogin },
  props: {
    user: Object,
    videoFull: Object,
    currentTime: Number,
     stageId: String
  },
  data: () => ({
    comment_reply: "",
    loginUser: {},
    params: {
      client_id: "638688976410-vivh8dj30ut84124t1rg1kgtkh70dn3q.apps.googleusercontent.com"
    }
  }),
  methods: {
    formatNumber(num) {
      return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    },

    /**
     * Cancel Reply
     * @param comment
     */
    cancelReply(comment) {
      this.comment_reply = "";
      comment.showReplyInput = false;
    },

    /**
     * Create Comment Reply
     * @param comment
     * @param comment_str
     * @param children
     */
    createComment({ comment, comment_str, children }) {
      if (comment_str === "") {
        return;
      }

      let parentId = null;

      if (children === true) {
        parentId = comment.id;
      }

      let post_data = {
        url: `/stage-public/${this.stageId}/editor/${this.videoFull.full.id}/comments/${this.loginUser.id}`,
        data: {
          id: this.videoFull.full.id,
          body: comment_str,
          parent_id: parentId,
          video_time: this.currentTime,
          returnHtml: false
        }
      };

      this.$store.dispatch("post", { ...post_data }).then(response => {
        if (children === true) {
          this.comment_reply = "";
          comment.showReplyInput = false;
          comment.children.push(response.data);
        } else {
          this.videoFull.comments.push(response.data);

          window.vEvent.fire("clear-stage-video-comment");
        }
      });
    },

    /**
     * Delete Comment
     * @param comment
     */
    deleteComment(comment) {
      if (comment.parent_id) {
        let parentComment = this.videoFull.comments
          .filter(pComment => {
            return pComment.id === comment.parent_id;
          })
          .shift();

        let index = parentComment.children.indexOf(comment);

        parentComment.children.splice(index, 1);
      } else {
        let index = this.videoFull.comments.indexOf(comment);

        this.videoFull.comments.splice(index, 1);
      }

      this.$store.dispatch(
        "delete",
        `/editor/${this.videoFull.full.id}/comments/${comment.id}/delete`
      );
    },

    /**/
    async logInWithFacebook() {
      window.FB.login(
        response => {
          if (response.authResponse) {
            console.log(response);
            let loginData = {
              id: response.authResponse.userID,
              type: "facebook"
            };
            console.log(window.FB.api);
            window.FB.api(
              "/me",
              { fields: "id,name,email,link,gender,locale,picture" },
              user => {
                console.log(user);
                loginData["name"] = user.name;
                loginData["email"] =
                  typeof user.email !== "undefined" ? user.email : "";
                const picture = user.picture;
                const pictureDate =
                  typeof picture.data !== "undefined" ? picture.data : "";

                loginData["photo_url"] =
                  typeof pictureDate.url !== "undefined" ? pictureDate.url : "";
                this.socialLogin(loginData);
              }
            );
          } else {
            alert("User cancelled login or did not fully authorize.");
          }
        },
        { scope: "email,user_likes", return_scopes: true }
      );
      return false;
    },
    async initFacebook() {
      window.fbAsyncInit = function() {
        window.FB.init({
          // appId: process.env.facebookId, //You will need to change this
          appId: "578439953088499", //You will need to change this
          cookie: true, // This is important, it's not enabled by default
          version: "v2.10",
          xfbml: true
        });
      };
    },
    async loadFacebookSDK(d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    },
    socialLogin(data) {
      this.axios({
        method: "post",
        url: "/comment_social_login",
        params: data
      })
        .then(response => {
          let data = response.data;
          this.loginUser = data.userInfo;
          this.setLogin();
          // this.$store.commit("setAccessToken", {
          //   token: data.access_token,
          //   expires: data.expires_in
          // });
          this.startFreeTrail(this.loginUser.id);
       
        })
        .catch(function(response) {
          console.log(response.message);
        });
    },
    startFreeTrail(user_id) {
      let url = process.env.BACKEND_URL + "social_free_trail/" + user_id;
      this.$store
        .dispatch("getData", {
          url
        })
        .then(response => {})
        .catch(error => {
          console.log(error);
        });
    },
    goToaccount() {
      this.$nextTick(() => {
        this.$store.dispatch("getUserData").then(re => {
          if (re.data.user_status === 1) {
            if (
              re.data.billing_status === "Inactive" ||
              re.data.billing_status === "Expired"
            ) {
              this.$router.push("/subscription");
              this.$forceUpdate();
            } else if (
              re.data.billing_status === "Cancelled" ||
              re.data.billing_status === "Failed"
            ) {
              window.BCookie.remove("BC-Auth-Email");
              this.errorsForm.accountCancelled = true;
              this.destroyAccessToken();
              this.goFirstStep();
            } else if (re.data.billing_status === "VerifyRequired") {
              window.BCookie.remove("BC-Auth-Email");
              this.errorsForm.accountNotVerified = true;
              this.errorsForm.email =
                "This account is not verified, please check your email.";
              this.userId = re.data.id;
              this.destroyAccessToken();
              this.goFirstStep();
            } else {
              const redirectUri = window.BCookie.check("redirect_path")
                ? window.BCookie.get("redirect_path")
                : "/";

              window.Ls.remove("BC-Auth-Remember");
              window.Ls.remove("sub-user-info");
              this.$router.push(redirectUri);
              this.$forceUpdate();
            }
          } else {
            window.BCookie.remove("BC-Auth-Email");
            this.errorsForm.accountCancelled = true;
            this.destroyAccessToken();
            this.goFirstStep();
          }
        });
      });
    },
    onSuccess(googleUser) {
      console.log(googleUser);
      let user = googleUser.getBasicProfile();
      let loginData = {};
      loginData["name"] = user.getName();
      loginData["email"] = user.getEmail();
      loginData["id"] = user.getId();
      loginData["photo_url"] = user.getImageUrl();
      loginData["type"] = "gmail";
      this.socialLogin(loginData);
    },
    onFailure(googleUser) {
      console.log(googleUser);

      // This only gets the user information: id, name, imageUrl and email
      //console.log(googleUser.getBasicProfile());
    },
    godashboard(){
         localStorage.removeItem("adilo_storedData_user");
         this.loginUser = {};
    },
    setLogin(){
      
      let myHour = new Date();
      myHour.setHours(myHour.getHours() + 24); 
      localStorage.setItem('adilo_storedData_time',JSON.stringify([myHour]));
      localStorage.setItem('adilo_storedData_user', JSON.stringify([this.loginUser]));
    },
    checkLogin(){
        const values = JSON.parse(localStorage.getItem('adilo_storedData_time'));
        if (values==null || values[0] < new Date()) {
          localStorage.removeItem("adilo_storedData_user");
          localStorage.removeItem("adilo_storedData_time");
          this.loginUser = {};
        }else{
         let user = JSON.parse(localStorage.getItem('adilo_storedData_user'));
         this.loginUser = user[0]
        }
    }
  },
  /**/ 
  mounted() {
    this.loadFacebookSDK(document, "script", "facebook-jssdk");
    this.initFacebook();
    this.checkLogin();

  },
  beforeCreate() {
    console.log("beforCreate hook has been called");
  },
  created() {
    
  },
  beforeMount() {
    console.log("beforeMount is called");
  },
  beforeUpdate() {
    console.log("beforeUpdate hook has been called");
  },
  updated() {
    console.log("Updated hook has been called");
  }
};
</script>

<style lang="less">
.stage-video-comments-wrap {
  padding: 6px 0;
  border: 0;
  color: #21455e;

  .comments {
     
    list-style: none;
    .comment {
      margin-top: 30px;

      .sub-comments {
        list-style: none;
        margin-left: 30px;
      }

      .comments-reply-line {
        display: flex;
        margin-left: 70px;
        margin-top: 30px;
        align-items: center;

        .reply-avatar {
          flex: none;
          margin-right: 20px;
        }

        .comment-reply-input {
          width: 280px;
          margin-right: 20px;
          height: 35px !important;

          .el-input__inner {
            height: 35px !important;
            line-height: 35px !important;
          }
        }

        .comment-reply-action {
          padding: 5px 12px !important;
        }
      }
    }
  }
  .alam.form-group {
    a {
      button {
        border: 0px;
        background-color: #0000;
      }
    }
  }
  .social_login {
    i {
      padding: 1px;
      position: absolute;
      top: -13px;
      right: -1px;
      background: #fff;
      color:#333;
      border-radius: 13px;
      padding: -1px;
      font-size: 12px;
      box-shadow: 0px 1px 2px 1px #8888883b;
      border: 1px solid #673ab71f;
      display: none;
    }
    &:hover {
      i {
        display: block;
      }
    }
  }
}
</style>

